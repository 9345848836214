import React from 'react'
import styles from "./navbar.module.scss"
import { useLocation } from 'react-router-dom'
import BalanceIcon from '../ui/images/BalanceIcon'


const Navbar = ({ name, balance }: any) => {
  const location = useLocation()

  return (
    <div className={styles.nav_cont} style={{ justifyContent: location.pathname === "/" ? "space-between" : "center" }}>
      <div>
        {name}
      </div>
      {
        location.pathname === "/" && <div className={styles.bal}>
          <span className={styles.bal_icon}>
            <BalanceIcon width={"16"} heigth={"16"} />
            Balance
          </span>
          <span>{balance}</span>
        </div>
      }

    </div>
  )
}

export default Navbar
