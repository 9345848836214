import React, { useEffect, useState } from "react";
import styles from "./games.module.scss";
import { useNavigate } from "react-router-dom";
import { gamesData, getLobby } from "../../api/api";
import Navbar from "../navbar/Navbar";
import { useSelector, useDispatch } from "../../redux/store";
import { userBalance } from "../../redux/slice/userBalanceSlice";
import Cookies from "js-cookie";
import Loading from "../ui/loading/Loading";
import "react-multi-carousel/lib/styles.css";
import CarouselSlider from "../carosolues/CarouselSlider";
import { Helmet } from "react-helmet";

const Games = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const balanceState = useSelector((state) => state.userReducer);
  const balance =
    balanceState.userBalance.deposit / 100 +
    balanceState.userBalance.withdrawls / 100;
  const formattedBalance = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(balance);

  const navigate = useNavigate();
  const [gamesList, setgamesList] = useState([]);

  useEffect(() => {
    dispatch(userBalance());
  }, [dispatch]);

  const token = Cookies.get("accessToken");

  const fetchGames = async () => {
    try {
      const res = await gamesData({
        token: token,
      });
      if (res?.status === 200) {
        setLoading(false);
        setgamesList(res?.data?.data?.providers[0].games);
      }
    } catch (error) {
      console.error("Error creating game:", error);
    }
  };

  useEffect(() => {
    fetchGames();
  }, []);

  const handleGLinkClick = async (e: any, gId: any) => {
    e.preventDefault();
    console.log("yyyyyyy", gId);
    try {
      const response = await getLobby({
        game: gId,
      });

      console.log("4234234", response);
      if (response?.status === 200) {
        navigate(
          `/lobby?gameId=${encodeURIComponent(
            response?.data?.data?.game_launcher
          )}`
        );
      }
      console.log("Game created:", response.data);
    } catch (error) {
      console.error("Error creating game:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>{"Rapid Games"}</title>
        <meta name="description" content="Experience the thrill of top-notch casino games. Our platform offers a seamless, interactive, and responsive gaming experience. Enjoy real-time gameplay with optimized performance, visually stunning graphics, and secure transactions. From classic card games to slot machines and live dealer options, our casino games provide endless entertainment. Perfectly designed for mobile, dive into an immersive world of betting and winning today!" />
        <meta name="keywords" content="Casino games,Online casino platform ,Real-time casino gaming,Mobile-friendly casino games
,Interactive casino games,Casino game development,Seamless casino gameplay,Real money casino games,Custom casino game design" />
      </Helmet>

      <div className={styles.game_cont}>
        <div className={styles.nav_GCont}>
          <Navbar name={"Rapid Games"} balance={formattedBalance} />
        </div>
        <CarouselSlider />
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className={styles.gameList_cont}>
              {gamesList?.map((g: any, i: any) => {
                return (
                  <>
                    <div
                      className={styles.gameImg}
                      onClick={(e) => handleGLinkClick(e, g?.code)}
                    >
                      <img src={g?.image?.url} className={styles.gImg} alt="" />
                    </div>
                  </>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Games;
