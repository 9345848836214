import React, { useEffect, useState } from "react";
import styles from "./withdrawhistory.module.scss";
import DepositeIcon from "../ui/images/DepositeIcon";
import { depositeHistory } from "../../api/api";
import Cookies from "js-cookie";
import Navbar from "../navbar/Navbar";
import Loading from "../ui/loading/Loading";
import HistoryIcon from "../ui/images/HistoryIcon";
import NoResult from "../ui/images/NoResult";

const WithDrawHistory = () => {
  const [loading, setLoading] = useState(true);
  const [depositedata, setDepositeData] = useState([]);
  let userId: any = Cookies.get("userId");
  let parseUserId = parseInt(userId);

  const fetchDepositeHistory = async () => {
    try {
      const res = await depositeHistory({
        userId: parseUserId,
        paymentType: "WITHDRAW",
      });
      if (res?.status === 200) {
        setLoading(false)
      }
      setDepositeData(res?.data?.data?.data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    fetchDepositeHistory();
  }, []);
  return (
    <>
      <div className={styles.deposite_box_main_wrap}>
        <div className={styles.nav_GCont}>
          <Navbar name={"Withdraw Transaction"} />
        </div>
        {loading ? <Loading /> :
          depositedata?.length === 0 ?
            <div className={styles.noresult_wrap}>
              <NoResult />
              <p className={styles.noresult}>No Withdraw Found</p>
            </div> : depositedata?.map((item: any) => {
              const updatedDate = new Date(item.updatedDate);
              const date = updatedDate.toLocaleDateString("en-IN");
              const time = updatedDate.toLocaleTimeString("en-IN", {
                hour: "2-digit",
                minute: "2-digit",
              });

              return (
                <div key={item.transactionId} className={styles.deposite_box}>
                  <div className={styles.deposite_left_side}>
                    <div className={styles.name_wrap}>
                      <HistoryIcon />
                      <h2 className={styles.payt_name}>
                        {item?.paymentGateway}</h2>
                    </div>
                    <p>Withdraw Amount</p>
                    <p>Transaction ID</p>
                    <p>{date} <span>{time}</span></p>
                  </div>

                  <div className={styles.deposite_right_side}>
                    <h2 className={styles.amt}>₹ {item?.amount / 100}</h2>
                    <p>₹ {item?.amount / 100}</p>
                    <p className={styles.transId}>{item?.transactionId}</p>
                    <p className={`${item.status === "FAILED" ? styles.failed : item.status === "CONFIRMED" ? styles.confirm : item.status === "DECLINED" ?
                      styles.declined : ""
                      }`}>{item?.status}</p>
                  </div>
                </div>
              );
            })}
      </div>
    </>
  );
};

export default WithDrawHistory;
