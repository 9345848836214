import React, { useEffect, useState } from 'react'
import styles from './promotions.module.scss'
import banner from '../ui/images/image 226.png'
import banner1 from '../ui/images/image 227.png'
import banne2 from '../ui/images/image 333.png'
import banne3 from '../ui/images/Mask group.png'
import banne4 from '../ui/images/image 231.png'
import banne5 from '../ui/images/image 230.png'
import banne6 from '../ui/images/image 229.png'
import { getPromotionBanner } from '../../api/api'
import { Helmet } from 'react-helmet'


const Promotions = () => {
    const [promotionbanner,setPromotionBanner] = useState([])
    console.log(promotionbanner,"dsjdsjduuuuuuuuu");

    const promotionsmallbanner = [
        {id:1,promotionImg:banner1},
        {id:2,promotionImg:banne6},
        {id:3,promotionImg:banne5},
        {id:3,promotionImg:banne4},
    ]

    useEffect(() => {
        const getBanner = async () => {
            const res = await getPromotionBanner();
            setPromotionBanner(res?.data?.data)
        }
        getBanner()
    },[])
    
  return (
    <>
    <Helmet>
        <title>{"Casino Promotions"}</title>
        <meta name="description" content="Unlock exciting promotions and exclusive offers at Rapid Games. Boost your winnings with generous bonuses. Whether you're a new player or a seasoned gamer, our promotions are designed to enhance your gaming experience and maximize your fun. Don’t miss out on special seasonal campaigns and VIP perks. Play your favorite casino games and let the rewards roll in!" />
        <meta name="keywords" content="Casino promotions,Best India casino bonuses,India's online casino deals
,Best casino promotions,Casino rewards program,Exclusive casino offers,Welcome bonus casino,Limited-time casino offers
" />
      </Helmet>
      <main className={styles.promotion_main_wrap}>
        <section className={styles.promotion_banner_list}>
            {
                promotionbanner.filter((sec:any) => sec.section === "1").map((list:any) => {
                    return (
                        <div key={list?.id}>
                            <img src={list?.fileUrl} alt='promotion_banner' width={"100%"} height={"100%"}/>
                        </div>
                    )
                })
            }
        </section>

        <section className={styles.promotion_small_banner}>
            {
                promotionsmallbanner?.map((list:any) => {
                    return (
                        <div>
                            <img src={list?.promotionImg} alt='promotion_banner' width={"250px"} height={"250px"}/>
                        </div>
                    )
                })
            }
        </section>
      </main>
    </>
  )
}

export default Promotions
