import React, { useEffect } from 'react'
import styles from "./userbalamce.module.scss"
import UserImage from '../ui/images/UserImage'
import { useDispatch, useSelector } from "../../redux/store";
import { userBalance } from '../../redux/slice/userBalanceSlice';
import IdIcon from '../ui/images/IdIcon';
import UserIconBal from '../ui/images/UserIconBal';

const UserBalance = () => {
    const dispatch = useDispatch();
    const balanceState = useSelector((state) => state.userReducer)
    const balance = (balanceState.userBalance.deposit / 100 + balanceState.userBalance.withdrawls / 100);
    const userId = localStorage.getItem("userId")
    const formattedBalance = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
    }).format(balance);

    useEffect(() => {
        dispatch(userBalance());
    }, [dispatch]);
    return (
        <>
            <main className={styles.user_bal_main_wrap}>
                <section className={styles.user_section}>
                    <UserIconBal />
                    <div>
                        <h2 className={styles.name}>John</h2>
                        <div className={styles.user_id}>
                            <IdIcon width={20} heigth={20}/>
                            <p className={styles.id}>UID:{userId}</p>
                        </div>
                    </div>
                </section>

                <section className={styles.user_balance_sec}>
                    <p className={styles.user_bal_amt}>{formattedBalance}</p>
                    <p className={styles.avl_bal}>Available Balance</p>
                </section>
            </main>
        </>
    )
}

export default UserBalance
