import React, { useState, useEffect } from 'react';
import './App.css';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Layout from './components/mainLayout/Layout';
import Login from './components/login/Login';
import Games from './components/games/Games';
import Iframe from './components/iframe/Iframe';
import UserProfile from './components/userprofile/UserProfile';
import ProtectedRoute from './ProductedRoute';
import DepositeHistory from './components/depositehistory/DepositeHistory';
import WithDrawHistory from './components/withdrawhistory/WithDrawHistory';
import Deposit from './components/deposit/Deposit';
import BottomNavbar from './components/BottomNavbar/BottomNavbar';
import MyTransaction from './components/mytransaction/MyTransaction';
import MyBet from './components/mybet/MyBet';
import Cookies from 'js-cookie';
import Promotions from './components/promotions/Promotions';
import TermsandCondition from './components/termscondition/TermsandCondition';
import SignUp from './components/signup/SignUp';
import Otp from './components/otp/Otp';
import SplashScreen from './components/splashscreen/SplashScreen';
import SetPassword from './components/setPassword/SetPassword';

function App() {
  const isAuthenticated = !!Cookies.get('accessToken');
  const location = useLocation();
  const hideNavbarRoutes = ['/login', '/lobby', '/signup', '/otp','/setpassword'];
  const shouldShowNavbar = !hideNavbarRoutes.includes(location.pathname);

  const [showSplashScreen, setShowSplashScreen] = useState(false);

  useEffect(() => {
    if (location.pathname === '/login') {
      setShowSplashScreen(true);
      const timer = setTimeout(() => {
        setShowSplashScreen(false);
      }, 5000);

      return () => clearTimeout(timer); 
    }
  }, [location.pathname]);

  if (showSplashScreen && location.pathname === '/login') {
    return <div style={{width:"100%",display:"flex",justifyContent:"center"}}> <SplashScreen /> </div>;
  }

  return (
    <>
      <div className='layout_main_wrap'>
        <Routes>
          <Route path="/login" element={isAuthenticated ? <Navigate to="/" replace /> : <Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/" element={<ProtectedRoute element={<Games />} />} />
          <Route path="/lobby" element={<Iframe />} />
          <Route path="/userprofile" element={<ProtectedRoute element={<UserProfile />} />} />
          <Route path="/depositehistory" element={<ProtectedRoute element={<DepositeHistory />} />} />
          <Route path="/withdrawhistory" element={<ProtectedRoute element={<WithDrawHistory />} />} />
          <Route path="/deposite" element={<ProtectedRoute element={<Deposit />} />} />
          <Route path="/alltransaction" element={<ProtectedRoute element={<MyTransaction />} />} />
          <Route path="/mybet" element={<ProtectedRoute element={<MyBet />} />} />
          <Route path="/promotions" element={<ProtectedRoute element={<Promotions />} />} />
          <Route path="/privacypolicy" element={<TermsandCondition />} />
          <Route path="/setpassword" element={<SetPassword />} />
        </Routes>
        {shouldShowNavbar && <BottomNavbar />}
      </div>
    </>
  );
}

export default App;
