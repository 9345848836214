import React, { useEffect, useState } from 'react';
import UserImage from '../ui/images/UserImage';
import styles from './userprofile.module.scss';
import DepositeIcon from '../ui/images/DepositeIcon';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'
import Navbar from '../navbar/Navbar';
import { useDispatch, useSelector } from "../../redux/store";
import Withdraw from '../ui/images/Withdraw';
import LogoutIcon from '../ui/images/LogoutIcon';
import MyBet from '../ui/images/MyBet';
import WithdrawTransIcon from '../ui/images/WithdrawTransIcon';
import MyTransactionIcon from '../ui/images/MyTransactionIcon';
import Balance from '../ui/images/Balance';
import BalanceIcon from '../ui/images/BalanceIcon';
import RightArrow from '../ui/images/RightArrow';
import { userBalance } from '../../redux/slice/userBalanceSlice';
import IdIcon from '../ui/images/IdIcon';
import Userbackground from '../ui/images/Userbackground';
import UserAvatar from '../useravatar/UserAvatar';
import logo from '../ui/images/myAvatar (1).png'
import logo1 from '../ui/images/myAvatar (2).png'
import logo2 from '../ui/images/myAvatar (4).png'
import logo3 from '../ui/images/myAvatar.png'
import logo4 from '../ui/images/1.png'
import logo5 from '../ui/images/2.png'
import logo6 from '../ui/images/3.png'
import logo7 from '../ui/images/4.png'
import logo8 from '../ui/images/5.png'
import logo9 from '../ui/images/6.png'
import logo10 from '../ui/images/7.png'
import logo11 from '../ui/images/8.png'
import logo12 from '../ui/images/9.png'
import logo13 from '../ui/images/10.png'
import logo14 from '../ui/images/myAvatar (9).png'
import ProfileEditIcon from '../ui/images/ProfileEditIcon';

import { getuserInfo, postProfileIcon } from '../../api/api';

const UserProfile = () => {
    const [selectedAvatarId, setSelectedAvatarId] = useState<any>(1);
    const [confirmedAvatarId, setConfirmedAvatarId] = useState<number>(1);
    const [avatarlist, setAvatarList] = useState<boolean>(false);
    const [userData,setUserData] = useState<any>({})
    
    console.log(userData?.profilePhotoPath === "","dsjbdjsbjdsuuuu");
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const balanceState = useSelector(state => state.userReducer)
    const balance = (balanceState.userBalance.deposit / 100 + balanceState.userBalance.withdrawls / 100);
    const formattedBalance = new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
    }).format(balance);

    useEffect(() => {
        dispatch(userBalance());
    }, [dispatch]);

    const handleNavigation = (route: string) => {
        navigate(route);
    };

    const userAvatars = [
        { id: 1, image:logo},
        { id: 2, image:logo1},
        { id: 3, image:logo2},
        { id: 4, image:logo3},
        { id: 5, image:logo4},
        { id: 6, image:logo5},
        { id: 7, image:logo6},
        { id: 8, image:logo7},
        { id: 9, image:logo8},
        { id: 10, image:logo9},
        { id: 11, image:logo10},
        { id: 12, image:logo11},
        { id: 13, image:logo12},
        { id: 14, image:logo13},
        { id: 15, image:logo14}
      ];

      const handleAvatarConfirmation = async () => {
        try {
          const selectedAvatar = userAvatars.find((avatar: any) => avatar.id === selectedAvatarId);
          if (!selectedAvatar) {
            console.error('Selected avatar not found');
            return;
          }
      
          // Fetch the image and convert it to a Blob
          const response = await fetch(selectedAvatar.image);
          const blob = await response.blob();
          const file = new File([blob], `avatar-${selectedAvatarId}.png`, { type: blob.type });
      
          // Prepare FormData
          const formData = new FormData();
          formData.append('profilePhoto', file);
    
          const res = await postProfileIcon(formData);
          console.log('Avatar updated successfully:', res.data);
          setConfirmedAvatarId(selectedAvatarId);
          setAvatarList(false);
        } catch (error) {
          console.error('Error updating avatar:', error);
        }
      };
      
      

    useEffect(() => {
       const fetchuserData = async () => {
        const res = await getuserInfo()
        console.log(res,"rnsjb");
        setUserData(res?.data?.data)
       }
        fetchuserData()
    },[confirmedAvatarId])


    const userTransactionList = [
        { name: "Withdraw Transactions", icon: <Withdraw />, link: '/withdrawhistory' },
        { name: "Deposite Transactions", icon: <DepositeIcon />, link: '/depositehistory' },
        { name: "My Transactions", icon: <MyTransactionIcon />, link: '/alltransaction' },
        { name: "My Bets", icon: <MyBet />, link: '/mybet' },
        { name: "Logout", icon: <LogoutIcon />, link: "" }
    ]

    const handleLogout = () => {
        Cookies.remove("accessToken", { path: '/' });
        Cookies.remove("userId", { path: '/' });
        localStorage.setItem("userId", "")
        navigate("/login", { replace: true });
    };

    const handleShowAvatar = () => {
        setAvatarList(!avatarlist)
    }

    return (
        <>
            <main className={styles.profile_main_wrap}>
                <div className={styles.profile_content_wrap}>
                    <section className={styles.user_info}>
                        <div className={styles.user_profile_image}>
                            <img src={userData?.profilePhotoPath === "" ? userAvatars[0]?.image : userData?.profilePhotoPath} alt='user_image' width={"100px"} height={"100px"}/>
                            <div className={styles.edit_profil_icon} onClick={handleShowAvatar}>
                            <ProfileEditIcon />
                        </div>
                        </div>
                       
                        <p className={styles.username}>{userData?.nickName}</p>
                        <div className={styles.border_bottm}></div>
                        <div className={styles.userId_info}>
                            <IdIcon width={22} heigth={22} />
                            <p className={styles.userId}>UID:{userData?.userId}</p>
                        </div>
                    </section>
                    {
                        avatarlist ? 
                        <UserAvatar
                            selectedAvatarId={selectedAvatarId}
                            setSelectedAvatarId={setSelectedAvatarId}
                            onConfirm={handleAvatarConfirmation}
                        /> :  
                        <>
                        <section className={styles.user_account_sec}>
                        <div className={styles.balance_sec}>
                            <div className={styles.icon}>
                                <BalanceIcon width={"25"} heigth={"25"}/>
                                <p className={styles.bal}>Balance</p>
                            </div>
                            <div className={styles.border_bottm}></div>
                            <p className={styles.bal_amt}>{formattedBalance}</p>
                        </div>

                        <div className={styles.withdraw_section}>
                            <div className={styles.icon} onClick={() => handleNavigation('/deposite')}>
                                <DepositeIcon />
                                <p className={styles.recharge}>Recharge</p>
                            </div>
                            <div className={styles.border_bottm}></div>
                            <div className={styles.withdraw_icon}>
                                <Withdraw />
                                <p className={styles.recharge}>Withdraw</p>
                            </div>
                        </div>
                    </section>


                    <section className={styles.user_menu_list}>
                        {
                            userTransactionList.map((list: any, index: number) => {
                                return (
                                    <div className={styles.menu_list}>
                                        <div className={styles.menu_icon_name}>
                                            <div className={styles.list_icon}
                                                onClick={() => {
                                                    if (list.name === "Logout") {
                                                        handleLogout();
                                                    } else {
                                                        handleNavigation(list.link);
                                                    }
                                                }}>
                                                {list.icon}
                                                <p className={styles.list_name}>{list.name}</p>
                                            </div>

                                            {index !== userTransactionList.length - 1 && (
                                                <div>
                                                    <div className={styles.border_bottm}></div>
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <RightArrow />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </section>
                    </>
                    }


                   
                </div>
            </main>
        </>
    );
};

export default UserProfile;
