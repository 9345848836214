import React, { useState } from 'react'
import styles from './depositeamt.module.scss'
import axios from 'axios'
import { addDeposite } from '../../api/api'
import UserBalance from '../userbalance/UserBalance'
import Navbar from '../navbar/Navbar'

const Deposit = () => {

  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false)
  const [paymentErrorRes,setPaymentErrorRes] = useState("")
  const [paymentError,setPaymentError] = useState(false)

  let minvalue = 100
  let maxvalue = 25000
  
  const amt_list = [
    { name: "₹ 100.00", value: 100 },
    { name: "₹ 500.00", value: 500 },
    { name: "₹ 1000.00", value: 1000 },
    { name: "₹ 5000.00", value: 5000 },
    { name: "₹ 10,000.00", value: 10000 },
    { name: "₹ 20,000.00", value: 20000 },
  ]


  const onSubmitAddCash = async () => {
    const parseAmount = parseInt(amount)
    if (parseAmount < minvalue || parseAmount > maxvalue || isNaN(parseAmount)) {
      setAmountError(true)
    } else {
      try {
        const res = await addDeposite({
          amount: parseInt(amount) * 100,
          ipAddress: "122121212",
          channelType: "WEBUSER"
        })
        if(res.status === 200){
          setPaymentError(false)
          setAmountError(false)
          setAmount("")
        }
      } catch (error:any) {
        console.log(error?.response?.data?.message, "error");
        setPaymentError(true)
        setAmountError(false)
        setPaymentErrorRes(error?.response?.data?.message)
      }
    }
  }

  return (
    <>
      <div>
        <div className={styles.nav_GCont}>
          <Navbar name={"Recharge"} />
        </div>
        <main className={styles.deposite_amt_main_wrap}>

          <UserBalance />
          <section className={styles.deposit_box_wrap}>
            <h3 className={styles.deposite}>Make a Deposite</h3>

            <div>
              <input type='number' placeholder='Enter a Amount' value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className={styles.amt_input} />
              {
                amountError && <p className={styles.error_msg}>Please Enter the valid amount</p>
              }
              {
                paymentError && <p className={styles.error_msg}>{paymentErrorRes}</p>
              }

              <div className={styles.amt_val}>
                <p className={styles.min}>Minimum - {minvalue}</p>
                <p className={styles.min}>Maximum - {maxvalue}</p>
              </div>
            </div>

            <div className={styles.amt_list_wrap}>
              {
                amt_list.map((list: any) => {
                  return (
                    <div className={` ${
                      amount === list.value ? styles.selected : styles.amt_value
                    }`}>
                      <p onClick={() => setAmount(list.value)}>{list.name}</p>
                    </div>
                  )
                })
              }
            </div>
          </section>

          <section className={styles.pay_button_wrap}>
            <p className={styles.btn_info}>By completing, you confirm your acceptance of the Offer T&C and Bonus T&C</p>
            <button className={styles.pay_btn} onClick={onSubmitAddCash}>Pay {amount}</button>
          </section>
        </main>
      </div>
    </>
  )
}

export default Deposit
