import { Draft, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios';
import Cookies from 'js-cookie';
import apiClient from '../../middlewear';

export interface BalanceData {
    userId: number,
    deposit: number,
    withdrawls: number,
    unSetteled:number,
    pendingWithdrawls: number,
    brandId:number,
    currency:"",
    chips: number
}

export interface userState {
    userBalance: BalanceData
}

const initialState: userState = {
    userBalance: {
        userId: 0,
        deposit: 0,
        withdrawls: 0,
        unSetteled:0,
        pendingWithdrawls: 0,
        brandId: 0,
        currency:"",
        chips: 0,
    }
}

export const UserSlice = createSlice({
    name: 'userState',
    initialState,
    reducers: {
        setUserBalance(
            state: Draft<typeof initialState>,
            action: PayloadAction<typeof initialState.userBalance>
        ) {
            state.userBalance = { ...state.userBalance, ...action.payload };
        }
    },
})

// Action creators are generated for each case reducer function
export const {  setUserBalance } = UserSlice.actions

export const userBalance = () => async (dispatch: any, getState: any) => {
    const token = Cookies.get("accessToken");
    const userid = Cookies.get("userId")
    console.log("rwerwuqerwi",token,userid)
    try {
        const {
            data: balanceData,
        } = await apiClient.get(`/wallet/user/${userid}/balance`
        );
        console.log("dafsadfsdf",balanceData.data)
        dispatch(setUserBalance(balanceData.data))

    } catch (err) {
        // dispatch(setErrorMessage(err.response.data.msg));
    }
};

export default UserSlice.reducer