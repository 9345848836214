import axios from 'axios';
import apiClient from '../middlewear';
import Cookies from 'js-cookie';

export const login = (data:any) => {
  return apiClient.post(`/user/login`,data)
}

export const signUp = (data:any) => {
  return apiClient.post(`/user/create/request/otp`,data)
}

export const postOtp = (data:any) => {
  return apiClient.post(`/user/create/token?token=${data?.token}&otp=${data?.otp}`,data)
}

export const setUserPassword = (data:any) => {
  const userId = localStorage.getItem("userId");
  return apiClient.put(`/user/${userId}/resetpassword`,{password:data?.password})
}

export const getLobby = (data:any) => {
    return apiClient.post(`/rgs/wat/lobby`,data);
};
  
export const gamesData = (data:any) => {
    return apiClient.post('/rgs/wat/games', data);
};

export const depositeHistory = (data:any) => {
  return apiClient.post(`/payment/user/history?pageNumber=${data?.pageNumber}&itemPerPage=${data?.itemPerPage}`,data)
}

export const addDeposite = (data:any) => {
  const userId = localStorage.getItem("userId");
  return apiClient.post(`/payment/deposit?userId=${userId}`,data)
}

export const allusertransaction = (data:any) => {
  const userId = localStorage.getItem("userId");
  return apiClient.post(`/wallet/user/${userId}/transaction?pageNumber=${data?.pageNumber}&itemPerPage=${data?.itemPerPage}`,data)
}

export const alluserbet = (data:any) => {
  const userId = localStorage.getItem("userId");
  return apiClient.post(`/wallet/user/${userId}/transaction?pageNumber=${data?.pageNumber}&itemPerPage=${data?.itemPerPage}`,data)
}

export const getuserInfo = () => {
  const userId = localStorage.getItem("userId");
  return apiClient.get(`/user/${userId}`)
}

export const getPromotionBanner = () => {
  return apiClient.get(`/cms/banner`)
}

export const postProfileIcon = (data:any) => {
  const userId = localStorage.getItem("userId");
  const authToken = Cookies.get("accessToken")
  return axios.post(`${process.env.REACT_APP_API_URL}/user/profilephoto/${userId}`,data,{
    headers: {
      'Content-Type': 'multipart/form-data',
      brandId: process.env.REACT_APP_API_BRAND_ID,
      Authorisation: authToken
    },
  })
}

export default apiClient;