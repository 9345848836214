import React, { useEffect, useState } from 'react'
import styles from './mybet.module.scss'
import DepositeIcon from '../ui/images/DepositeIcon'
import { alluserbet } from '../../api/api'
import Cookies from 'js-cookie'
import Navbar from '../navbar/Navbar'
import Loading from '../ui/loading/Loading'
import PaginationComp from '../pagination/PaginationComp'
import HistoryIcon from '../ui/images/HistoryIcon'
import NoResult from '../ui/images/NoResult'

const MyBet = () => {
  const [loading, setLoading] = useState(true);
  const [depositedata, setDepositeData] = useState([])
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(0);

  let userId: any = Cookies.get("userId")
  let parseUserId = parseInt(userId)

  const filters = [
    "ALL","TODAY","CONFIRMED","FAILED","DECLINED"
  ]

  const fetchAllMybet = async (page:any) => {
    try {
      const res = await alluserbet({
        transactionType: "DEBIT",
        pageNumber: page,
        itemPerPage: 15,
      })
      if (res?.status === 200) {
        setLoading(false)
        setDepositeData(res?.data?.data?.data);
        setTotalPages(res?.data?.data?.totalRecords || 1);
      }
     
    } catch (error) {
      console.log(error, "error");

    }
  }

  useEffect(() => {
    fetchAllMybet(pageNumber);
  }, [pageNumber])
  return (
    <>


      <div className={styles.deposite_box_main_wrap}>
        <div className={styles.nav_GCont}>
          <Navbar name={"My Bet"} />
        </div>
        {loading ? <Loading /> :
          (depositedata?.length === 0 ? <div className={styles.noresult_wrap}>
            <NoResult />
            <p className={styles.noresult}>No Withdraw Found</p>
          </div> : (
            depositedata?.map((item: any) => {
              const updatedDate = new Date(item.updatedAt);
              const date = updatedDate.toLocaleDateString('en-IN');
              const time = updatedDate.toLocaleTimeString('en-IN', { hour: '2-digit', minute: '2-digit' });
              return <div className={styles.deposite_box}>
              <div className={styles.deposite_left_side}>
             <div className={styles.name_wrap}>
             <HistoryIcon />
               <h2 className={styles.payt_name}>
                 {item?.transactionType}</h2>
             </div>
             <p>Balance Amount</p>
             <p>Transaction ID</p>
             <p>{date} <span>{time}</span></p>
           </div>

           <div className={styles.deposite_right_side}>
             <h2 className={styles.amt}>₹ {item?.amount / 100}</h2>
             <p>₹ {item?.balance / 100}</p>
             <p className={styles.transId}>{item?.transactionId}</p>
             <p className={`${item.status === "FAILED" ? styles.failed : item.status === "CONFIRMED" ? styles.confirm : item.status === "DECLINED" ?
               styles.declined : ""
               }`}>{item?.description}</p>
           </div>
             </div>
            })
          )
          )}
        {
        depositedata.length !== 0 ?
          <PaginationComp
            totalPages={totalPages}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber} /> : ""
      }
      </div>
    </>
  )
}

export default MyBet

