import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import styles from "../games/games.module.scss"

const Iframe = () => {

  const location = useLocation();
  const [gameId, setGameId] = useState<string | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    
    const gameIdParam = params.get('gameId') 
    setGameId(gameIdParam);
    console.log("Params object:", params.toString());
    console.log("Extracted gameId:", gameIdParam);  }, [location.search]);

    console.log("sdfsdfs",gameId)

  return (
    <div className={styles.game_cont}>
       <iframe
          src={gameId ?? undefined}
          title="Game"
          style={{
            width: "100%",
            height: "100vh",
            border: "none",
          }}
        />
    </div>
  )
}

export default Iframe
