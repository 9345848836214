import React, { useState, useRef } from 'react';
import styles from './otp.module.scss';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { postOtp } from '../../api/api';
import Logo from '../ui/images/Logo';

const Otp = () => {
    const [otp, setOtp] = useState(Array(6).fill(''));
    const [errMsgres,setErrMsgres] = useState("")
    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);
    const navigate = useNavigate();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value;
        if (!/^\d*$/.test(value)) return;
        const updatedOtp = [...otp];
        updatedOtp[index] = value.slice(-1);
        setOtp(updatedOtp);

        if (value && index < 5) {
            inputsRef.current[index + 1]?.focus();
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace') {
            const updatedOtp = [...otp];
            updatedOtp[index] = '';
            setOtp(updatedOtp);

            if (index > 0 && otp[index] === '') {
                inputsRef.current[index - 1]?.focus();
            }
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const otpValue = otp.join('');
        if (otpValue.length !== 6) {
            toast.error('Please enter a valid 6-digit OTP.');
            return;
        }

        try {
            const res = await postOtp({
                token: Cookies.get('accessToken'),
                otp: otpValue,
                userName:Cookies.get('mobile')
            });
            if (res.status === 200) {
                Cookies.set("accessToken", res?.data?.data?.accessToken);
                Cookies.set("userId", res?.data?.data?.UserId);
                localStorage.setItem("userId", res?.data?.data?.UserId);
                navigate('/setpassword', { replace: true });
              } else if (res?.status === 400) {
                setErrMsgres(res?.data?.message);
              } else {
                console.log("Login unsuccessful");
              }
        } catch (error:any) {
            console.log(error,"errorororo");
            
            const errorMessage = error.response?.data?.message
            setErrMsgres(errorMessage);
        }
    };

    return (
        <form className={styles.login_main_wrap} onSubmit={handleSubmit}>
            <div className={styles.logn_sub}>
                <div>
                    <Logo />
                </div>
                <h1 className={styles.otp_heading}>Enter the OTP</h1>
                <section className={styles.input_otpbox_wrap}>
                    {otp.map((_, index) => (
                        <input
                            key={index}
                            type="text"
                            className={styles.otpbox}
                            maxLength={1}
                            value={otp[index]}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            ref={(el) => (inputsRef.current[index] = el)}
                        />
                    ))}
                </section>
                {errMsgres && <span className={styles.errormsg}>{errMsgres}</span>}
            </div>

            <section className={styles.btn_section}>
                <button type="submit" className={styles.lg_btn}>
                    Submit
                </button>
            </section>
        </form>
    );
};

export default Otp;
